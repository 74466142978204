<template>
  <div class="settings-page">
    <div class="settings-page__header">
      <Title>Settings</Title>
      <Button is-small is-raised @click="handleOpenOnboardingGuide">
        {{ onboardingText }}
      </Button>
    </div>
    <div class="settings-page__list">
      <router-link
        v-for="(item, index) in routes"
        :key="index"
        :to="item.route"
        class="settings-page__list__item"
        :class="{
          disabled: item.isDisabled,
          full: index === routes.length - 1 && routes.length % 2 !== 0,
        }"
        v-bind="item.attributes"
      >
        {{ item.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { SETTINGS_MENU_ITEMS } from "@/helpers/mocks";
import { mapGetters, mapState } from "vuex";

export default {
  name: "SettingsPage",
  computed: {
    ...mapState({
      isSuperadmin: (state) => state.users.isSuperadmin,
      currentOnboardingStep: (state) => state.users.currentOnboardingStep,
      isOnboardingFinished: (state) => state.users.isOnboardingFinished,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    routes() {
      return [...SETTINGS_MENU_ITEMS].filter(
        (route) =>
          (!route.permission || this.checkPermission(route.permission)) &&
          (!route.isSuperadmin || this.isSuperadmin)
      );
    },
    onboardingText() {
      if (this.currentOnboardingStep === -1) {
        return this.isOnboardingFinished
          ? "Run onboarding guide again"
          : "Run onboarding guide";
      }
      return "Continue onboarding guide";
    },
  },
  methods: {
    handleOpenOnboardingGuide() {
      const step =
        this.currentOnboardingStep === -1 ? 0 : this.currentOnboardingStep;
      this.$tours.onboardingGuide?.start(step);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    @media (min-width: $media-laptop) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 16px;
    }

    &__item {
      padding: 24px 0;
      border-bottom: 1px solid $secondary-300;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-500;
      text-decoration: none;

      &:hover {
        color: #778192;
      }

      &:active {
        color: $primary-hover;
      }

      &.disabled {
        color: $secondary-300;
        pointer-events: none;
      }

      &.full {
        grid-column: span 2;
      }
    }
  }
}
</style>
